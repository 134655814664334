<template>
  <div>
    <b-row>
      <b-col>
        <b-link class="d-flex justify-content-start align-items-center">
          <b-img
            :src="appLogoImage"
            alt="logo"
            height="64px"
            class="mr-1 logo"
          />
          <h2
            class="brand-text"
          >
            {{ appName }}
          </h2>
        </b-link>
      </b-col>
    </b-row>

    <b-row class="p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ $t('You are not authorized') }}! 🔐
        </h2>
        <p class="mb-2">
          {{ $t('You don’t have permission to access this page') }}
        </p>
        <p>
          <b-button
            variant="primary"
            class="mb-1 btn-sm-block"
            :to="loginRoute()"
          >
            <span class="align-middle">{{ $t('auth.Back to login') }}</span>
          </b-button>
        </p>

        <b-img
          fluid
          :src="imgUrl"
          alt="Not authorized page"
        />
      </div>
    </b-row>
  </div>
</template>

<script>

import {
  BLink, BImg, BButton, BCol, BRow,
} from 'bootstrap-vue'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink, BImg, BButton, BCol, BRow,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/not-authorized.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
    loginRoute() {
      const user = JSON.parse(localStorage.getItem('userData'))
      return getHomeRouteForLoggedInUser(user ? user.role : null)
    },
  },
  setup() {
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName, appLogoImage,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
